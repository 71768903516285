import axios from 'axios';
import { getUserData } from '../util/getUserData';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    //if err don't do any thing and i will handel it in my global handel error
    return Promise.reject(error);
  }
);
export const isUserExist = async (email) => {
  const result = await axiosInstance.post('/is-email-exist', { email });
  return result.data.data;
};
export async function submitAnswers(answers, id) {
  const user = getUserData();
  try {
    answers.user_id = user.id;
    const x = await axios.post(`${process.env.REACT_APP_API_URL}/answers/${id}`, answers);
    toast.success('Thank you for your submit');
    const newUser = await isUserExist(localStorage.getItem('email'));
    localStorage.setItem('user', JSON.stringify(newUser));
  } catch (err) {
    if (err.request.status === 0) {
      toast.error(`${err.code}\nPlease Check Your Internet Connection`);
    }
    toast.error(err.response.data.message);
  }
  if (id === 8) return `${user.currentPage}/sketching`;
  else return user.currentPage;
}
export async function resetWorkshops(id) {
  const user = getUserData();
  try {
    const x = await axios.delete(`${process.env.REACT_APP_API_URL}/workshop/${id}/${user.id}`);
    toast.success('workshop reset successful');
  } catch (err) {
    if (err.request.status === 0) {
      toast.error(`${err.code}\nPlease Check Your Internet Connection`);
    }
    toast.error(err.response.data.message);
  }
  return user.currentPage;
}

export const exportData = async (id, fileName) => {
  const user = getUserData();
  try {
    const x = await axios.get(`${process.env.REACT_APP_API_URL}/export-excel/${id}/${user.id}`, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([x.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}_${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    toast.success('workshop export successful');
  } catch (err) {
    if (err.request.status === 0) {
      toast.error(`${err.code}\nPlease Check Your Internet Connection`);
    }
    toast.error(err.response.data.message);
  }
  return user.currentPage;
};
